import React from 'react';

import { Site } from '../components/Site';
import { Container } from '../components/ui/Container';
import { Beatstars } from '../components/Beatstars';

const seo = {
  title: 'Beats',
};

const IndexPage = ({ ...other }) => {
  return (
    <Site seo={seo} {...other}>
      <Container maxWidth={1600}>
        <Beatstars />
      </Container>
    </Site>
  );
};

export default IndexPage;
