import styled from 'styled-components';

export const BeatstarsIframe = styled.iframe`
  width: 100%;
  height: 800px;
  max-width: 1200px;

  @media only screen and (max-width: 648px) {
    max-height: 800px;
    height: calc(100vh - 80px);
  }
`;
