import React from 'react';
import { Container } from '../ui/Container';
import { LoadingMessage } from '../LoadingMessage/LoadingMessage';
import { BeatstarsIframe } from './styled';

const Beatstars = () => (
  <Container maxWidth={1200}>
    <LoadingMessage />
    <BeatstarsIframe src="https://player.beatstars.com/?storeId=106263" scrolling="no" />
  </Container>
);

export { Beatstars };
